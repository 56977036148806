// extracted by mini-css-extract-plugin
export const placeholder = "Demo-module--placeholder--2VEJT";
export const svgWrapper = "Demo-module--svgWrapper--320qy";
export const svg = "Demo-module--svg--1-l4u";
export const ref = "Demo-module--ref--2gp1a";
export const info = "Demo-module--info--DHCBf";
export const shuttle = "Demo-module--shuttle--1iWeU";
export const shuttleWrapper = "Demo-module--shuttleWrapper--DoKmn";
export const base = "Demo-module--base--31Ttt";
export const bg = "Demo-module--bg--3Imck";
export const booster = "Demo-module--booster--2T5I0";
export const text = "Demo-module--text--egjde";
export const title = "Demo-module--title--2Y1MP";
export const startButton = "Demo-module--startButton--Kmue0";
export const githubButton = "Demo-module--githubButton--1Lv_s";
export const space = "Demo-module--space--2D7Jy";
export const hovering = "Demo-module--hovering--2UG5C";
export const bounce = "Demo-module--bounce--1O_37";